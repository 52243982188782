// composables/useTheme.js
import { ref, watch } from 'vue'

const theme = ref(localStorage.getItem('theme') || 'system')

export function useTheme() {
  const setTheme = (newTheme) => {
    theme.value = newTheme
    localStorage.setItem('theme', newTheme)
    updateThemeClass()
  }

  const updateThemeClass = () => {
    if (theme.value === 'dark' || (theme.value === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  watch(theme, updateThemeClass)

  return {
    theme,
    setTheme
  }
}