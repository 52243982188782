import revive_payload_client_WzbdMmTDsr from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OEci2TDcgY from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2TQ3joFIKE from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Gg2eKshOlH from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WiyXARnhr9 from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_E4u3zkn1wL from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bjl5kV4Edh from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/jdo/org/brx/artifacts_frontend_brx/.nuxt/components.plugin.mjs";
import prefetch_client_FS3Kms8ifV from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_prod_client_0mQiVnPP3i from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt-monaco-editor@1.2.9_magicast@0.3.5_monaco-editor@0.52.0_rollup@4.24.0_vite@5.4.9_@types+_vaed7ejn7xb36vpbbnkjjb6czq/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.mjs";
import plugin_6Ph1iQzNHk from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import firebase_Wjz9XrZo01 from "/Users/jdo/org/brx/artifacts_frontend_brx/plugins/firebase.ts";
import emoji_picker_KzpRdgEzIK from "/Users/jdo/org/brx/artifacts_frontend_brx/plugins/emoji-picker.js";
export default [
  revive_payload_client_WzbdMmTDsr,
  unhead_OEci2TDcgY,
  router_2TQ3joFIKE,
  payload_client_Gg2eKshOlH,
  navigation_repaint_client_WiyXARnhr9,
  check_outdated_build_client_E4u3zkn1wL,
  chunk_reload_client_bjl5kV4Edh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FS3Kms8ifV,
  plugin_prod_client_0mQiVnPP3i,
  plugin_6Ph1iQzNHk,
  firebase_Wjz9XrZo01,
  emoji_picker_KzpRdgEzIK
]