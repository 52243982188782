import { micromark } from 'micromark'
import { gfm, gfmHtml } from 'micromark-extension-gfm'
import { underline, underlineHtml } from './underline'
import { usertag, usertagHtml } from './usertag'

export default function markdown(text, { textFormatting }) {
  if (textFormatting) {
    let gfmDisabled = []

    if (!textFormatting.linkify) {
      gfmDisabled = ['literalAutolink', 'literalAutolinkEmail']
    }

    const markdownResult = micromark(
      text.replaceAll('<usertag>', '<@').replaceAll('</usertag>', '>'),
      {
        extensions: [
          {
            ...gfm(),
            disable: { null: gfmDisabled }
          },
          underline,
          usertag
        ],
        htmlExtensions: [
          gfmHtml(),
          underlineHtml,
          usertagHtml(textFormatting.users)
        ]
      }
    )

    if (textFormatting.singleLine) {
      const element = document.createElement('div')

      element.innerHTML = markdownResult

      return [
        {
          types: [],
          value: element.innerText
        }
      ]
    }

    return [
      {
        types: ['markdown'],
        value: markdownResult
      }
    ]
  }

  return [
    {
      types: [],
      value: text
    }
  ]
}
