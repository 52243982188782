import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth"
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics"
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig()

    const firebaseConfig = {
      apiKey: "AIzaSyB_jRbxwr3UbzRtemomF0tGPmw8dZfRNe8",
      authDomain: "brx-frontend.firebaseapp.com",
      databaseURL: "https://brx-frontend-default-rtdb.firebaseio.com",
      projectId: "brx-frontend",
      storageBucket: "brx-frontend.appspot.com",
      messagingSenderId: "1071624419706",
      appId: "1:1071624419706:web:5dfb2a6e5275b321223ec3",
      measurementId: "G-MYE7ZSZ29W"
    };

    const fbapp = initializeApp(firebaseConfig)

    // console.log("FIREBASE STARTING")
    // console.log(app)

    // const analytics = getAnalytics(app)
    const auth = getAuth(fbapp)
    const firestore = getFirestore(fbapp)
    const realtimeDb = getDatabase(fbapp)
    const storage = getStorage(fbapp)


    // console.log("INIT SERVICE")
    // console.log(auth)
    // console.log(firestore)

    // nuxtApp.vueApp.provide('app', fbapp)
    // nuxtApp.provide('app', fbapp)

    // nuxtApp.vueApp.provide('auth', auth)
    // nuxtApp.provide('auth', auth)

    // nuxtApp.vueApp.provide('firestore', firestore)
    // nuxtApp.provide('firestore', firestore)
    
    // nuxtApp.vueApp.provide('realtimeDb', realtimeDb)
    // nuxtApp.provide('realtimeDb', realtimeDb)

    // nuxtApp.vueApp.provide('storage', storage)
    // nuxtApp.provide('storage', storage)

    return {
      provide: {
        fbapp,
        auth,
        firestore,
        realtimeDb,
        storage
      }
  }
})
