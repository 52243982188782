<template>
  <div>
    <Card class="overflow-hidden">
      <CardHeader class="bg-muted py-2">
        <CardTitle class="text-sm flex items-center">
          <ListChecks class="w-4 h-4 mr-2" />
          SRE PROCESS
          <Badge class="ml-2 text-xs">ARTIFACT</Badge>
          <Badge class="ml-2 text-xs">{{ processOP }}</Badge>
        </CardTitle>
      </CardHeader>
      <CardContent class="p-2">
        <div class="space-y-1">
          <div v-for="(step, index) in sortedSteps" :key="step.name" class="flex items-center space-x-2">
            <div 
              :class="`w-4 h-4 rounded-full flex items-center justify-center ${getStepStatusClass(step, index)}`"
            >
              <Check v-if="step.status === 'completed'" class="w-3 h-3 text-white" />
              <Loader2 v-else-if="step.status === 'in-progress'" class="w-3 h-3 text-white animate-spin" />
              <AlertCircle v-else-if="step.status === 'error'" class="w-3 h-3 text-white" />
            </div>
            <div class="flex-grow flex items-center justify-between">
              <span :class="`text-xs ${getStepTextClass(step.status)}`">
                {{ step.name }}
              </span>
              <span class="text-xs text-muted-foreground font-normal">
                {{ formatTime(step.timeConsumed) }}
              </span>
            </div>
            <span v-if="step.rollbackStepName" class="text-xs text-muted-foreground font-normal">
              ↰ {{ step.rollbackStepName }}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  
    <OperationalMessages :operational-messages="operationalMessages" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Check, Loader2, AlertCircle, ListChecks } from 'lucide-vue-next'
import OperationalMessages from '@/components/OperationalMessages.vue'

interface StateExecutionStatus {
  isRunning: boolean;
  hasFinished: boolean;
  timeConsumed: number; // in milliseconds
  rollbackStep: number;
  rollbackStepName: string;
  stepIndex: number;
  error?: any;
}

interface Step {
  name: string;
  status: 'pending' | 'in-progress' | 'completed' | 'error';
  timeConsumed: number;
  rollbackStepName: string;
  stepIndex: number;
  error?: any;
}

interface OperationalMessage {
  content: string;
}

const props = defineProps<{
  stateRuntime: Record<string, StateExecutionStatus>,
  operationalMessages: OperationalMessage[]
}>()

const emit = defineEmits(['log', 'updateStepStatus'])

const processOP = ref("v0.11.2cb")
const steps = ref<Step[]>([])

const getStatus = (stepData: StateExecutionStatus): Step['status'] => {
  if (stepData.hasFinished) return 'completed'
  if (stepData.isRunning) return 'in-progress'
  if (stepData.error) return 'error'
  return 'pending'
}

const updateSteps = (stateRuntime: Record<string, StateExecutionStatus>) => {
  steps.value = Object.entries(stateRuntime).map(([key, value]) => ({
    name: key,
    status: getStatus(value),
    timeConsumed: value.timeConsumed,
    rollbackStepName: value.rollbackStepName,
    stepIndex: value.stepIndex,
    error: value.error
  }))
  emit('log', `Steps updated: ${steps.value.length} steps`)
}

// Use watch to react to changes in stateRuntime
watch(() => props.stateRuntime, (newStateRuntime) => {
  if (newStateRuntime) {
    updateSteps(newStateRuntime)
  }
}, { deep: true, immediate: true })

// Use onMounted to ensure initial hydration
onMounted(() => {
  if (props.stateRuntime) {
    updateSteps(props.stateRuntime)
  }
})

const sortedSteps = computed(() => {
  return [...steps.value].sort((a, b) => a.stepIndex - b.stepIndex)
})

const getStepStatusClass = (step: Step, index: number) => {
  const nextStep = sortedSteps.value[index + 1]
  if (step.status === 'completed') return 'bg-green-500'
  if (step.status === 'in-progress') return 'bg-yellow-500'
  if (step.status === 'error') return 'bg-red-500'
  if (nextStep && nextStep.status === 'in-progress') return 'bg-yellow-500'
  return 'bg-gray-300 dark:bg-gray-600'
}

const getStepTextClass = (status: Step['status']) => {
  switch (status) {
    case 'completed':
      return 'text-muted-foreground'
    case 'in-progress':
      return 'font-medium'
    case 'error':
      return 'text-red-500 font-medium'
    default:
      return ''
  }
}

const formatTime = (timeInMs: number) => {
  const seconds = Math.floor(timeInMs / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  
  if (hours > 0) {
    return `${hours}h ${minutes % 60}m`
  } else if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`
  } else {
    return `${seconds}s`
  }
}

const updateStepStatus = (index: number, newStatus: Step['status']) => {
  if (index >= 0 && index < steps.value.length) {
    steps.value[index].status = newStatus
    emit('log', `Step "${steps.value[index].name}" status updated to ${newStatus}`)
    emit('updateStepStatus', steps.value[index].name, newStatus)
  }
}

const addStep = (stepName: string, status: Step['status'] = 'pending', rollbackStepName = '', stepIndex = steps.value.length) => {
  steps.value.push({ name: stepName, status, timeConsumed: 0, rollbackStepName, stepIndex })
  emit('log', `New step added: ${stepName}`)
}

const renameStep = (index: number, newName: string) => {
  if (index >= 0 && index < steps.value.length) {
    const oldName = steps.value[index].name
    steps.value[index].name = newName
    emit('log', `Step renamed from "${oldName}" to "${newName}"`)
  }
}

defineExpose({ updateStepStatus, addStep, renameStep })
</script>