<template>
  <div
    class="vac-format-message-wrapper"
    :class="{ 'vac-text-ellipsis': singleLine }"
  >
    <template v-for="(message, i) in parsedMessage" :key="i">
      <div
        v-if="message.markdown"
        class="markdown"
        @click="openTag"
        v-html="message.value"
      />
      <div
        v-else
        class="vac-format-container"
        :class="{ 'vac-text-ellipsis': singleLine }"
      >
        <component
          :is="message.url ? 'a' : 'span'"
          :class="{
            'vac-text-ellipsis': singleLine,
            'vac-text-tag': !singleLine && !reply && message.tag
          }"
          :href="message.href"
          :target="message.href ? linkOptions.target : null"
          :rel="message.href ? linkOptions.rel : null"
        >
          <template v-if="deleted">
            <slot
              :name="
                roomList
                  ? 'deleted-icon-room_' + roomId
                  : 'deleted-icon_' + messageId
              "
            >
              <svg-icon
                name="deleted"
                class="vac-icon-deleted"
                :class="{ 'vac-icon-deleted-room': roomList }"
              />
            </slot>
            {{ textMessages.MESSAGE_DELETED }}
          </template>
          <template v-else-if="message.url && message.image">
            <div class="vac-image-link-container">
              <div
                class="vac-image-link"
                :style="{
                  'background-image': `url('${message.value}')`,
                  height: message.height
                }"
              />
            </div>
            <div class="vac-image-link-message">
              {{ message.value }}
            </div>
          </template>
          <template v-else>
            {{ message.value }}
          </template>
        </component>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import SvgIcon from '../SvgIcon/SvgIcon'

import markdown from '../../utils/markdown'
import { IMAGE_TYPES } from '../../utils/constants'

const props = defineProps({
  messageId: { type: String, default: '' },
  roomId: { type: String, default: '' },
  roomList: { type: Boolean, default: false },
  content: { type: [String, Number], required: true },
  deleted: { type: Boolean, default: false },
  users: { type: Array, default: () => [] },
  linkify: { type: Boolean, default: true },
  singleLine: { type: Boolean, default: false },
  reply: { type: Boolean, default: false },
  textFormatting: { type: Object, required: true },
  textMessages: { type: Object, default: () => ({}) },
  linkOptions: { type: Object, required: true }
})

const emit = defineEmits(['open-user-tag'])

const parsedMessage = computed(() => {
  if (props.deleted) {
    return [{ value: props.textMessages.MESSAGE_DELETED }]
  }

  let options
  if (!props.textFormatting.disabled) {
    options = {
      textFormatting: {
        linkify: props.linkify,
        linkOptions: props.linkOptions,
        singleLine: props.singleLine,
        reply: props.reply,
        users: props.users,
        ...props.textFormatting
      }
    }
  } else {
    options = {}
  }

  const message = markdown(props.content, options)

  message.forEach(m => {
    m.markdown = checkType(m, 'markdown')
    m.tag = checkType(m, 'tag')
    m.image = checkImageType(m)
  })

  return message
})

const checkType = (message, type) => {
  return message.types && message.types.indexOf(type) !== -1
}

const checkImageType = (message) => {
  let index = message.value.lastIndexOf('.')
  const slashIndex = message.value.lastIndexOf('/')
  if (slashIndex > index) index = -1

  const type = message.value.substring(index + 1, message.value.length)

  const isMedia =
    index > 0 && IMAGE_TYPES.some(t => type.toLowerCase().includes(t))

  if (isMedia) setImageSize(message)

  return isMedia
}

const setImageSize = (message) => {
  const image = new Image()
  image.src = message.value

  image.addEventListener('load', onLoad)

  function onLoad(img) {
    const ratio = img.path[0].width / 150
    message.height = Math.round(img.path[0].height / ratio) + 'px'
    image.removeEventListener('load', onLoad)
  }
}

const openTag = (event) => {
  const userId = event.target.getAttribute('data-user-id')
  if (!props.singleLine && userId) {
    const user = props.users.find(u => String(u._id) === userId)
    emit('open-user-tag', user)
  }
}
</script>