<!-- File: app.vue (or your main app component) -->
<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <CreateProjectPopup />
     <Toaster />
  </div>
</template>

<script setup>
import { ref, provide } from 'vue'
import CreateProjectPopup from '~/components/CreateProjectPopup.vue'
import Toaster from '@/components/ui/toast/Toaster.vue'

const isCreateProjectPopupOpen = ref(false)

const openCreateProjectPopup = () => {
  isCreateProjectPopupOpen.value = true
}

const closeCreateProjectPopup = () => {
  isCreateProjectPopupOpen.value = false
}

provide('isCreateProjectPopupOpen', isCreateProjectPopupOpen)
provide('openCreateProjectPopup', openCreateProjectPopup)
provide('closeCreateProjectPopup', closeCreateProjectPopup)
</script>