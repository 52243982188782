<template>
  <div
    v-show="(isMobile && !showRoomsList) || !isMobile || singleRoom"
    class="vac-col-messages"
    @drop.prevent="onDropFiles"
    @dragenter.prevent
    @dragover.prevent
    @dragleave.prevent
    @touchstart="touchStart"
  >
    <slot v-if="showNoRoom" name="no-room-selected">
      <div class="vac-container-center vac-room-empty">
        <div>{{ textMessages.ROOM_EMPTY }}</div>
        <div>
          <button @click="createNewSession">Create New Session</button>
        </div>
      </div>
    </slot>

    <room-header
      v-else
      :current-user-id="currentUserId"
      :text-messages="textMessages"
      :single-room="singleRoom"
      :show-rooms-list="showRoomsList"
      :is-mobile="isMobile"
      :room-info-enabled="roomInfoEnabled"
      :menu-actions="menuActions"
      :room="room"
      :message-selection-enabled="messageSelectionEnabled"
      :message-selection-actions="messageSelectionActions"
      :selected-messages-total="selectedMessages.length"
      @toggle-rooms-list="$emit('toggle-rooms-list')"
      @room-info="$emit('room-info')"
      @menu-action-handler="$emit('menu-action-handler', $event)"
      @message-selection-action-handler="messageSelectionActionHandler"
      @cancel-message-selection="messageSelectionEnabled = false"
    >
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </room-header>

		<div
			id="messages-list"
			ref="scrollContainer"
			class=""
			@scroll="onContainerScroll"
		>
			<loader :show="loadingMessages" type="messages">
				<template v-for="(idx, name) in $slots" #[name]="data">
					<slot :name="name" v-bind="data" />
				</template>
			</loader>
			<div class="vac-messages-container">
				<div :class="{ 'vac-messages-hidden': loadingMessages }">
					<transition name="vac-fade-message">
						<div>
							<div v-if="showNoMessages" class="vac-text-started">
								<slot name="messages-empty">
									{{ textMessages.MESSAGES_EMPTY }}
								</slot>
							</div>
							<div v-if="showMessagesStarted" class="vac-text-started">
								{{ textMessages.CONVERSATION_STARTED }} {{ messages[0].date }}
							</div>
						</div>
					</transition>
					<div
						v-if="messages.length && !messagesLoaded"
						id="infinite-loader-messages"
					>
						<loader :show="true" :infinite="true" type="infinite-messages">
							<template v-for="(idx, name) in $slots" #[name]="data">
								<slot :name="name" v-bind="data" />
							</template>
						</loader>
					</div>
					<transition-group :key="roomId" name="vac-fade-message" tag="span">
						<div v-for="(m, i) in messages" :key="m.indexId || m._id">
							<room-message
								:current-user-id="currentUserId"
								:message="m"
								:index="i"
								:messages="messages"
								:edited-message-id="editedMessageId"
								:message-actions="messageActions"
								:room-users="room.users"
								:text-messages="textMessages"
								:new-messages="newMessages"
								:show-reaction-emojis="showReactionEmojis"
								:show-new-messages-divider="showNewMessagesDivider"
								:text-formatting="textFormatting"
								:link-options="linkOptions"
								:username-options="usernameOptions"
								:message-selection-enabled="messageSelectionEnabled"
								:selected-messages="selectedMessages"
								:emoji-data-source="emojiDataSource"
								@message-added="onMessageAdded"
								@message-action-handler="messageActionHandler"
								@open-file="openFile"
								@open-user-tag="openUserTag"
								@open-failed-message="$emit('open-failed-message', $event)"
								@send-message-reaction="sendMessageReaction"
								@select-message="selectMessage"
								@unselect-message="unselectMessage"
							>
								<template v-for="(idx, name) in $slots" #[name]="data">
									<slot :name="name" v-bind="data" />
								</template>
							</room-message>
						</div>
					</transition-group>
				</div>
			</div>
		</div>
		<div v-if="!loadingMessages">
			<transition name="vac-bounce">
				<div v-if="scrollIcon" class="vac-icon-scroll" @click="scrollToBottom">
					<transition name="vac-bounce">
						<div
							v-if="scrollMessagesCount"
							class="vac-badge-counter vac-messages-count"
						>
							{{ scrollMessagesCount }}
						</div>
					</transition>
					<slot name="scroll-icon">
						<svg-icon name="dropdown" param="scroll" />
					</slot>
				</div>
			</transition>
		</div>

		<room-footer
			:room="room"
			:room-id="roomId"
			:room-message="roomMessage"
			:text-messages="textMessages"
			:show-send-icon="showSendIcon"
			:show-files="showFiles"
			:show-audio="showAudio"
			:show-emojis="showEmojis"
			:show-footer="showFooter"
			:accepted-files="acceptedFiles"
			:capture-files="captureFiles"
			:multiple-files="multipleFiles"
			:textarea-action-enabled="textareaActionEnabled"
			:textarea-auto-focus="textareaAutoFocus"
			:user-tags-enabled="userTagsEnabled"
			:emojis-suggestion-enabled="emojisSuggestionEnabled"
			:templates-text="templatesText"
			:text-formatting="textFormatting"
			:link-options="linkOptions"
			:audio-bit-rate="audioBitRate"
			:audio-sample-rate="audioSampleRate"
			:init-reply-message="initReplyMessage"
			:init-edit-message="initEditMessage"
			:dropped-files="droppedFiles"
			:emoji-data-source="emojiDataSource"
			@update-edited-message-id="editedMessageId = $event"
			@edit-message="$emit('edit-message', $event)"
			@send-message="$emit('send-message', $event)"
			@typing-message="$emit('typing-message', $event)"
			@textarea-action-handler="$emit('textarea-action-handler', $event)"
		>
			<template v-for="(idx, name) in $slots" #[name]="data">
				<slot :name="name" v-bind="data" />
			</template>
		</room-footer>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import FirestoreManager from '@/components/chat/database/firestore';
import FirebaseStatusManager from '@/components/chat/database/firebase';
import FirebaseStorageManager from '@/components/chat/database/storage';


const nuxtApp = useNuxtApp();
let firestoreService = new FirestoreManager(nuxtApp.$firestore)
let firebaseService = new FirebaseStatusManager(nuxtApp.$realtimeDb)
let storageService = new FirebaseStorageManager(nuxtApp.$storage)
import Loader from '../../components/Loader/Loader'
import SvgIcon from '../../components/SvgIcon/SvgIcon'
import RoomHeader from './RoomHeader/RoomHeader'
import RoomFooter from './RoomFooter/RoomFooter'
import RoomMessage from './RoomMessage/RoomMessage'
import { useAuth } from '@/composables/useAuth'


const props = defineProps({

  currentUserId: { type: [String, Number], required: true },

  textMessages: { type: Object, required: true },

  singleRoom: { type: Boolean, required: true },

  showRoomsList: { type: Boolean, required: true },

  isMobile: { type: Boolean, required: true },

  rooms: { type: Array, required: true },

  roomId: { type: [String, Number], required: true },

  loadFirstRoom: { type: Boolean, required: true },

  messages: { type: Array, required: true },

  roomMessage: { type: String, default: null },

  messagesLoaded: { type: Boolean, required: true },

  menuActions: { type: Array, required: true },

  messageActions: { type: Array, required: true },

  messageSelectionActions: { type: Array, required: true },

  autoScroll: { type: Object, required: true },

  showSendIcon: { type: Boolean, required: true },

  showFiles: { type: Boolean, required: true },

  showAudio: { type: Boolean, required: true },

  audioBitRate: { type: Number, required: true },

  audioSampleRate: { type: Number, required: true },

  showEmojis: { type: Boolean, required: true },

  showReactionEmojis: { type: Boolean, required: true },

  showNewMessagesDivider: { type: Boolean, required: true },

  showFooter: { type: Boolean, required: true },

  acceptedFiles: { type: String, required: true },

  captureFiles: { type: String, required: true },

  multipleFiles: { type: Boolean, default: true },

  textFormatting: { type: Object, required: true },

  linkOptions: { type: Object, required: true },

  loadingRooms: { type: Boolean, required: true },

  roomInfoEnabled: { type: Boolean, required: true },

  textareaActionEnabled: { type: Boolean, required: true },

  textareaAutoFocus: { type: Boolean, required: true },

  userTagsEnabled: { type: Boolean, required: true },

  emojisSuggestionEnabled: { type: Boolean, required: true },

  scrollDistance: { type: Number, required: true },

  templatesText: { type: Array, default: () => [] },

  usernameOptions: { type: Object, required: true },

  emojiDataSource: { type: String, default: undefined }

})


const emit = defineEmits([

  'toggle-rooms-list',

  'room-info',

  'menu-action-handler',

  'message-selection-action-handler',

  'edit-message',

  'send-message',

  'delete-message',

  'message-action-handler',

  'fetch-messages',

  'send-message-reaction',

  'typing-message',

  'open-file',

  'open-user-tag',

  'open-failed-message',

  'textarea-action-handler'

])


const { user, error, logout, login } = useAuth()

const editedMessageId = ref(null)
const initReplyMessage = ref(null)
const initEditMessage = ref(null)
const loadingMessages = ref(false)
const observer = ref(null)
const showLoader = ref(true)
const loadingMoreMessages = ref(false)
const scrollIcon = ref(false)
const scrollMessagesCount = ref(0)
const newMessages = ref([])
const messageSelectionEnabled = ref(false)
const selectedMessages = ref([])
const droppedFiles = ref([])

const room = computed(() => {
  return props.rooms.find(room => room.roomId === props.roomId) || {}
})

const showNoMessages = computed(() => {
  return (
    props.roomId &&
    !props.messages.length &&
    !loadingMessages.value &&
    !props.loadingRooms
  )
})

const showNoRoom = computed(() => {
  const noRoomSelected =
    (!props.rooms.length && !props.loadingRooms) ||
    (!props.roomId && !props.loadFirstRoom)

  if (noRoomSelected) {
    updateLoadingMessages(false)
  }
  return noRoomSelected
})

const showMessagesStarted = computed(() => {
  return props.messages.length && props.messagesLoaded
})

watch(() => props.roomId, onRoomChanged, { immediate: true })

watch(
  () => props.messages,
  (newVal, oldVal) => {
    newVal.forEach((message, i) => {
      if (
        props.showNewMessagesDivider &&
        !message.seen &&
        message.senderId !== props.currentUserId
      ) {
        newMessages.value.push({
          _id: message._id,
          index: i
        })
      }
    })
    if (oldVal?.length === newVal?.length - 1) {
      newMessages.value = []
    }
    setTimeout(() => (loadingMoreMessages.value = false))
  },
  { deep: true }
)

watch(() => props.messagesLoaded, (val) => {
  if (val) updateLoadingMessages(false)
})

onMounted(() => {
  newMessages.value = []
})

async function createNewSession() {
  console.log(user.value)
  let tval = {
    _id: user.value.uid,
    username: user.value.displayName,
    email: user.value.email,
    avatar: user.value.photoURL
  }
  console.log(tval)
  console.log("-=-=-=-=-=-==-=-=-=-=")
  await firestoreService.addIdentifiedUser(user.value.uid, tval)
  await firestoreService.addRoom({ users: [user.value.uid], lastUpdated: new Date() })
  location.reload()
}

function updateLoadingMessages(val) {
  loadingMessages.value = val

  if (!val) {
    setTimeout(() => initIntersectionObserver())
  }
}

function initIntersectionObserver() {
  if (observer.value) {
    showLoader.value = true
    observer.value.disconnect()
  }

  const loader = document.querySelector('#infinite-loader-messages')

  if (loader) {
    const options = {
      root: document.querySelector('#messages-list'),
      rootMargin: `${props.scrollDistance}px`,
      threshold: 0
    }

    observer.value = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMoreMessages()
      }
    }, options)

    observer.value.observe(loader)
  }
}

function preventTopScroll() {
  const container = document.querySelector('#messages-list')
  const prevScrollHeight = container.scrollHeight

  const resizeObserver = new ResizeObserver(_ => {
    if (container.scrollHeight !== prevScrollHeight) {
      if (container) {
        container.scrollTo({
          top: container.scrollHeight - prevScrollHeight
        })
        resizeObserver.disconnect()
      }
    }
  })

  for (var i = 0; i < container.children.length; i++) {
    resizeObserver.observe(container.children[i])
  }
}

function touchStart(touchEvent) {
  if (props.singleRoom) return

  if (touchEvent.changedTouches.length === 1) {
    const posXStart = touchEvent.changedTouches[0].clientX
    const posYStart = touchEvent.changedTouches[0].clientY

    addEventListener(
      'touchend',
      touchEvent => touchEnd(touchEvent, posXStart, posYStart),
      { once: true }
    )
  }
}

function touchEnd(touchEvent, posXStart, posYStart) {
  if (touchEvent.changedTouches.length === 1) {
    const posXEnd = touchEvent.changedTouches[0].clientX
    const posYEnd = touchEvent.changedTouches[0].clientY

    const swippedRight = posXEnd - posXStart > 100
    const swippedVertically = Math.abs(posYEnd - posYStart) > 50

    if (swippedRight && !swippedVertically) {
      emit('toggle-rooms-list')
    }
  }
}

function onRoomChanged() {
  updateLoadingMessages(true)
  scrollIcon.value = false
  scrollMessagesCount.value = 0
  resetMessageSelection()

  const stopWatch = watch(() => props.messages, (val) => {
    if (!val || !val.length) return

    const element = document.querySelector('#messages-list')
    if (!element) return

    stopWatch()

    nextTick(() => {
      element.scrollTo({ top: element.scrollHeight })
      updateLoadingMessages(false)
    })
  })
}

function resetMessageSelection() {
  messageSelectionEnabled.value = false
  selectedMessages.value = []
}

function selectMessage(message) {
  selectedMessages.value.push(message)
}

function unselectMessage(messageId) {
  selectedMessages.value = selectedMessages.value.filter(
    message => message._id !== messageId
  )
}

function onMessageAdded({ message, index, ref }) {
  if (index !== props.messages.length - 1) return

  const autoScrollOffset = ref.offsetHeight + 60

  setTimeout(() => {
    const scrollContainer = document.querySelector('#messages-list')
    let scrolledUp = false

    if (scrollContainer) {
      scrolledUp = getBottomScroll(scrollContainer) > autoScrollOffset
    }

    if (message.senderId === props.currentUserId) {
      if (scrolledUp) {
        if (props.autoScroll.send.newAfterScrollUp) {
          scrollToBottom()
        }
      } else {
        if (props.autoScroll.send.new) {
          scrollToBottom()
        }
      }
    } else {
      if (scrolledUp) {
        if (props.autoScroll.receive.newAfterScrollUp) {
          scrollToBottom()
        } else {
          scrollIcon.value = true
          scrollMessagesCount.value++
        }
      } else {
        if (props.autoScroll.receive.new) {
          scrollToBottom()
        } else {
          scrollIcon.value = true
          scrollMessagesCount.value++
        }
      }
    }
  })
}

function onContainerScroll(e) {
  if (!e.target) return

  const bottomScroll = getBottomScroll(e.target)
  if (bottomScroll < 60) scrollMessagesCount.value = 0
  scrollIcon.value = bottomScroll > 500 || scrollMessagesCount.value
}

function loadMoreMessages() {
  if (loadingMessages.value) return

  setTimeout(
    () => {
      if (loadingMoreMessages.value) return

      if (props.messagesLoaded || !props.roomId) {
        loadingMoreMessages.value = false
        showLoader.value = false
        return
      }

      preventTopScroll()
      emit('fetch-messages')
      loadingMoreMessages.value = true
    },
    500
  )
}

function messageActionHandler({ action, message }) {
  switch (action.name) {
    case 'replyMessage':
      initReplyMessage.value = message
      setTimeout(() => {
        initReplyMessage.value = null
      })
      return
    case 'editMessage':
      initEditMessage.value = message
      setTimeout(() => {
        initEditMessage.value = null
      })
      return
    case 'deleteMessage':
      return emit('delete-message', message)
    case 'selectMessages':
      selectedMessages.value = [message]
      messageSelectionEnabled.value = true
      return
    default:
      return emit('message-action-handler', { action, message })
  }
}

function messageSelectionActionHandler(action) {
  emit('message-selection-action-handler', {
    action,
    messages: selectedMessages.value
  })
  resetMessageSelection()
}

function sendMessageReaction(messageReaction) {
  emit('send-message-reaction', messageReaction)
}

function getBottomScroll(element) {
  const { scrollHeight, clientHeight, scrollTop } = element
  return scrollHeight - clientHeight - scrollTop
}

function scrollToBottom() {
  setTimeout(() => {
    const element = document.querySelector('#messages-list')
    if (element) {
      element.classList.add('vac-scroll-smooth')
      element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' })
      setTimeout(() => element.classList.remove('vac-scroll-smooth'))
    }
  }, 50)
}

function openFile({ message, file }) {
  emit('open-file', { message, file })
}

function openUserTag(user) {
  emit('open-user-tag', user)
}

function onDropFiles(event) {
  if (props.showFiles) {
    droppedFiles.value = event.dataTransfer.files
  }
}

// Expose necessary methods and reactive variables to the template
</script>