<!-- File: components/CreateProjectPopup.vue -->
<template >
  <Dialog :open="isOpen" @update:open="handleUpdateOpen">
    <DialogContent class="sm:max-w-[1000px]">
      <DialogHeader>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogDescription>
          Enter the details for your new project.
        </DialogDescription>
      </DialogHeader>
      <div class="grid gap-4 py-4">
        <div
          class="app-container"
          :class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }"
        >
          <client-only>
            <chat-container
              v-if="showChat && user"
              :current-user-id="user.uid"
              :theme="theme"
              :is-device="isDevice"
              @selected-room="current_room_id = $event"
              @show-demo-options="showDemoOptions = $event"
              @messageSelectionActionHandler="messageSelectionActionHandler($event)"
            />
          </client-only>
          <div v-if="!user" class="login-container">
            <h2 class="text-2xl font-bold text-center mb-4">Welcome to BRX artifacts</h2>
            <p class="text-center mb-6">
              Please log in to access the service and create your project.
            </p>
            <div class="flex justify-center">
              <Button @click="login" class="w-full sm:w-auto">
                <LogIn class="mr-2 h-4 w-4" />
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DialogFooter>
        <!-- <Button @click="createProject">Create</Button> -->
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup>
import { ref, inject , computed, watch, onMounted, toRaw} from 'vue'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '@/components/ui/dialog'
import { LogIn } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import FirestoreManager from '@/components/chat/database/firestore';
import FirebaseStatusManager from '@/components/chat/database/firebase';
import FirebaseStorageManager from '@/components/chat/database/storage';
import { useAuth } from '@/composables/useAuth';
import ChatContainer from '@/components/chat/ChatContainerCreate';
import BRX from "brx-node";

const nuxtApp = useNuxtApp();
let firestoreService = new FirestoreManager(nuxtApp.$firestore);
let firebaseService = new FirebaseStatusManager(nuxtApp.$realtimeDb);
let storageService = new FirebaseStorageManager(nuxtApp.$storage);
const { user, error, logout, login } = useAuth();

const theme = ref('dark');
const showChat = ref(true);
const current_room_id = ref('');
const isDevice = ref(false);
const showDemoOptions = ref(true);
const updatingData = ref(false);
const show_brx_ops = ref(false);
const show_brx_guided_ops = ref(false);
const isOpen = inject('isCreateProjectPopupOpen')
const closePopup = inject('closeCreateProjectPopup')

// Computed property in Vue 3
const showOptions = computed(() => !isDevice.value || showDemoOptions.value)

watch(user, () => {
  showChat.value = false
  setTimeout(() => (showChat.value = true), 150)
})

onMounted(() => {
  isDevice.value = window.innerWidth < 500
  window.addEventListener('resize', ev => {
    if (ev.isTrusted) isDevice.value = window.innerWidth < 500
  })
})

const handleUpdateOpen = (value) => {
  if (!value) {
    closePopup()
  }
}

const messageSelectionActionHandler = (event) => {
  // Implement message selection action handler
  console.log('Message selection action:', event)
}
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 70vh;
  padding: 1rem;
}
</style>