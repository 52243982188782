import {
	onDisconnect,
	onValue,
	ref,
	serverTimestamp,
	set
  } from 'firebase/database'
  
  class FirebaseStatusManager {
	constructor(realtimeDb) {
	  this.realtimeDb = realtimeDb
	//   console.log("Inside FirebaseStatusManager")
	//   console.log(this.realtimeDb)
	}
  
	firebaseListener = onValue
  
	userStatusRef(userId) {
	  return ref(this.realtimeDb, '/status/' + userId)
	}
  
	updateUserOnlineStatus(currentUserId) {
	  const isOfflineData = {
		state: 'offline',
		lastChanged: serverTimestamp()
	  }
	  const isOnlineData = {
		state: 'online',
		lastChanged: serverTimestamp()
	  }
	  const connectedRef = ref(this.realtimeDb, '.info/connected')
	  onValue(connectedRef, snap => {
		if (snap.val() === true) {
		  onDisconnect(this.userStatusRef(currentUserId))
			.set(isOfflineData)
			.then(() => {
			  set(this.userStatusRef(currentUserId), isOnlineData)
			})
		}
	  })
	}
  }
  
  export default FirebaseStatusManager