<template>
  <div class="" :class="{ 'dark': isDarkTheme }" :style="[{  }, cssVars]">
    <div class="">
      

      <room
        :current-user-id="currentUserId"
        :rooms="roomsCasted"
        :room-id="room.roomId || ''"
        :load-first-room="loadFirstRoomCasted"
        :messages="messagesCasted"
        :room-message="roomMessage"
        :messages-loaded="messagesLoadedCasted"
        :menu-actions="menuActionsCasted"
        :message-actions="messageActionsCasted"
        :message-selection-actions="messageSelectionActionsCasted"
        :auto-scroll="autoScrollCasted"
        :show-send-icon="showSendIconCasted"
        :show-files="showFilesCasted"
        :show-audio="showAudioCasted"
        :audio-bit-rate="audioBitRate"
        :audio-sample-rate="audioSampleRate"
        :show-emojis="showEmojisCasted"
        :show-reaction-emojis="showReactionEmojisCasted"
        :show-new-messages-divider="showNewMessagesDividerCasted"
        :show-footer="showFooterCasted"
        :text-messages="t"
        :single-room="singleRoomCasted"
        :show-rooms-list="showRoomsList && roomsListOpenedCasted"
        :text-formatting="textFormattingCasted"
        :link-options="linkOptionsCasted"
        :is-mobile="isMobile"
        :loading-rooms="loadingRoomsCasted"
        :room-info-enabled="roomInfoEnabledCasted"
        :textarea-action-enabled="textareaActionEnabledCasted"
        :textarea-auto-focus="textareaAutoFocusCasted"
        :user-tags-enabled="userTagsEnabledCasted"
        :emojis-suggestion-enabled="emojisSuggestionEnabledCasted"
        :scroll-distance="scrollDistance"
        :accepted-files="acceptedFiles"
        :capture-files="captureFiles"
        :multiple-files="multipleFilesCasted"
        :templates-text="templatesTextCasted"
        :username-options="usernameOptionsCasted"
        :emoji-data-source="emojiDataSource"
        @toggle-rooms-list="toggleRoomsList"
        @room-info="roomInfo"
        @fetch-messages="fetchMessages"
        @send-message="sendMessage"
        @edit-message="editMessage"
        @delete-message="deleteMessage"
        @open-file="openFile"
        @open-user-tag="openUserTag"
        @open-failed-message="openFailedMessage"
        @menu-action-handler="menuActionHandler"
        @message-action-handler="messageActionHandler"
        @message-selection-action-handler="messageSelectionActionHandler"
        @send-message-reaction="sendMessageReaction"
        @typing-message="typingMessage"
        @textarea-action-handler="textareaActionHandler"
      >
        <template v-for="el in slots" #[el.slot]="data">
          <slot :name="el.slot" v-bind="data" />
        </template>
      </room>
    </div>
    <transition name="vac-fade-preview" appear>
      <media-preview
        v-if="showMediaPreview"
        :file="previewFile"
        @close-media-preview="showMediaPreview = false"
      >
        <template v-for="el in slots" #[el.slot]="data">
          <slot :name="el.slot" v-bind="data" />
        </template>
      </media-preview>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUpdated } from 'vue'
import RoomsList from './RoomsList/RoomsList.vue'
import Room from './Room/Room.vue'
import MediaPreview from './MediaPreview/MediaPreview.vue'

import locales from '../locales'
import { defaultThemeStyles, cssThemeVars } from '../themes'
import {
  roomsValidation,
  partcipantsValidation
} from '../utils/data-validation'
import { useTheme } from '../composables/useTheme'

const props = defineProps({
  project: {type: Object, default: null},
  height: { type: String, default: '100px' },
  styles: { type: [Object, String], default: () => ({}) },
  responsiveBreakpoint: { type: Number, default: 900 },
  singleRoom: { type: [Boolean, String], default: false },
  roomsListOpened: { type: [Boolean, String], default: false },
  textMessages: { type: [Object, String], default: () => ({}) },
  currentUserId: { type: String, default: '' },
  rooms: { type: [Array, String], default: () => [] },
  roomsOrder: { type: String, default: 'desc' },
  loadingRooms: { type: [Boolean, String], default: false },
  roomsLoaded: { type: [Boolean, String], default: false },
  roomId: { type: String, default: null },
  loadFirstRoom: { type: [Boolean, String], default: true },
  messages: { type: [Array, String], default: () => [] },
  messagesLoaded: { type: [Boolean, String], default: false },
  roomActions: { type: [Array, String], default: () => [] },
  menuActions: { type: [Array, String], default: () => [] },
  messageActions: {
    type: [Array, String],
    default: () => [
      { name: 'replyMessage', title: 'Reply' },
      { name: 'editMessage', title: 'Edit Message', onlyMe: true },
      { name: 'deleteMessage', title: 'Delete Message', onlyMe: true },
      { name: 'selectMessages', title: 'Select' }
    ]
  },
  messageSelectionActions: { type: [Array, String], default: () => [] },
  autoScroll: {
    type: [Object, String],
    default: () => ({
      send: {
        new: true,
        newAfterScrollUp: true
      },
      receive: {
        new: true,
        newAfterScrollUp: false
      }
    })
  },
  customSearchRoomEnabled: { type: [Boolean, String], default: false },
  showSearch: { type: [Boolean, String], default: true },
  showAddRoom: { type: [Boolean, String], default: true },
  showSendIcon: { type: [Boolean, String], default: true },
  showFiles: { type: [Boolean, String], default: true },
  showAudio: { type: [Boolean, String], default: false },
  audioBitRate: { type: Number, default: 128 },
  audioSampleRate: { type: Number, default: new (window.AudioContext || window.webkitAudioContext)().sampleRate },
  showEmojis: { type: [Boolean, String], default: false },
  showReactionEmojis: { type: [Boolean, String], default: false },
  showNewMessagesDivider: { type: [Boolean, String], default: true },
  showFooter: { type: [Boolean, String], default: true },
  textFormatting: {
    type: [Object, String],
    default: () => ({
      disabled: false
    })
  },
  linkOptions: {
    type: [Object, String],
    default: () => ({ disabled: false, target: '_blank', rel: null })
  },
  roomInfoEnabled: { type: [Boolean, String], default: false },
  textareaActionEnabled: { type: [Boolean, String], default: false },
  textareaAutoFocus: { type: [Boolean, String], default: true },
  userTagsEnabled: { type: [Boolean, String], default: true },
  emojisSuggestionEnabled: { type: [Boolean, String], default: true },
  roomMessage: { type: String, default: '' },
  scrollDistance: { type: Number, default: 60 },
  acceptedFiles: { type: String, default: '*' },
  captureFiles: { type: String, default: '' },
  multipleFiles: { type: [Boolean, String], default: true },
  templatesText: { type: [Array, String], default: () => [] },
  mediaPreviewEnabled: { type: [Boolean, String], default: true },
  usernameOptions: {
    type: [Object, String],
    default: () => ({ minUsers: 3, currentUser: false })
  },
  emojiDataSource: { type: String, default: undefined }
})

const emit = defineEmits([
  'toggle-rooms-list',
  'room-info',
  'fetch-messages',
  'send-message',
  'edit-message',
  'delete-message',
  'open-file',
  'open-user-tag',
  'open-failed-message',
  'menu-action-handler',
  'message-action-handler',
  'send-message-reaction',
  'typing-message',
  'textarea-action-handler',
  'fetch-more-rooms',
  'add-room',
  'search-room',
  'room-action-handler',
  'message-selection-action-handler'
])

const slots = ref([])
const room = ref({})
const loadingMoreRooms = ref(false)
const showRoomsList = ref(castBoolean(props.roomsListOpened))
const isMobile = ref(false)
const showMediaPreview = ref(false)
const previewFile = ref({})

const { theme } = useTheme()

const t = computed(() => ({
  ...locales,
  ...castObject(props.textMessages)
}))

const isDarkTheme = computed(() => {
  return theme.value === 'dark' || (theme.value === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches)
})

const cssVars = computed(() => {
  const defaultStyles = isDarkTheme.value ? defaultThemeStyles.dark : defaultThemeStyles.light
  const customStyles = {}

  Object.keys(defaultStyles).forEach(key => {
    customStyles[key] = {
      ...defaultStyles[key],
      ...(stylesCasted.value[key] || {})
    }
  })

  return cssThemeVars(customStyles)
})

const orderedRooms = computed(() => {
  return roomsCasted.value.slice().sort((a, b) => {
    const aVal = a.index || 0
    const bVal = b.index || 0

    if (props.roomsOrder === 'asc') {
      return aVal < bVal ? -1 : bVal < aVal ? 1 : 0
    }

    return aVal > bVal ? -1 : bVal > aVal ? 1 : 0
  })
})

const singleRoomCasted = computed(() => castBoolean(props.singleRoom))
const roomsListOpenedCasted = computed(() => castBoolean(props.roomsListOpened))
const loadingRoomsCasted = computed(() => castBoolean(props.loadingRooms))
const roomsLoadedCasted = computed(() => castBoolean(props.roomsLoaded))
const loadFirstRoomCasted = computed(() => castBoolean(props.loadFirstRoom))
const messagesLoadedCasted = computed(() => castBoolean(props.messagesLoaded))
const multipleFilesCasted = computed(() => castBoolean(props.multipleFiles))
const showSearchCasted = computed(() => castBoolean(props.showSearch))
const showAddRoomCasted = computed(() => castBoolean(props.showAddRoom))
const showSendIconCasted = computed(() => castBoolean(props.showSendIcon))
const showFilesCasted = computed(() => castBoolean(props.showFiles))
const showAudioCasted = computed(() => castBoolean(props.showAudio))
const showEmojisCasted = computed(() => castBoolean(props.showEmojis))
const showReactionEmojisCasted = computed(() => castBoolean(props.showReactionEmojis))
const showNewMessagesDividerCasted = computed(() => castBoolean(props.showNewMessagesDivider))
const showFooterCasted = computed(() => castBoolean(props.showFooter))
const roomInfoEnabledCasted = computed(() => castBoolean(props.roomInfoEnabled))
const textareaActionEnabledCasted = computed(() => castBoolean(props.textareaActionEnabled))
const textareaAutoFocusCasted = computed(() => castBoolean(props.textareaAutoFocus))
const userTagsEnabledCasted = computed(() => castBoolean(props.userTagsEnabled))
const emojisSuggestionEnabledCasted = computed(() => castBoolean(props.emojisSuggestionEnabled))
const mediaPreviewEnabledCasted = computed(() => castBoolean(props.mediaPreviewEnabled))

const roomsCasted = computed(() => castArray(props.rooms))
const messagesCasted = computed(() => castArray(props.messages))
const roomActionsCasted = computed(() => castArray(props.roomActions))
const menuActionsCasted = computed(() => castArray(props.menuActions))
const messageActionsCasted = computed(() => castArray(props.messageActions))
const messageSelectionActionsCasted = computed(() => castArray(props.messageSelectionActions))
const templatesTextCasted = computed(() => castArray(props.templatesText))

const stylesCasted = computed(() => castObject(props.styles))
const autoScrollCasted = computed(() => castObject(props.autoScroll))
const textFormattingCasted = computed(() => castObject(props.textFormatting))
const linkOptionsCasted = computed(() => castObject(props.linkOptions))
const usernameOptionsCasted = computed(() => castObject(props.usernameOptions))

const showRoomsListComputed = computed(() => showRoomsList.value) //&& roomsListOpenedCasted.value

function castBoolean(val) {
  return val === 'true' || val === true
}

function castArray(val) {
  return !val ? [] : Array.isArray(val) ? val : JSON.parse(val)
}

function castObject(val) {
  return !val ? {} : typeof val === 'object' ? val : JSON.parse(val)
}

function updateResponsive() {
  isMobile.value = window.innerWidth < Number(props.responsiveBreakpoint)
}

function toggleRoomsList() {
  showRoomsList.value = !showRoomsList.value
  if (isMobile.value) room.value = {}
  emit('toggle-rooms-list', { opened: showRoomsList.value })
}

function fetchRoom({ room: fetchedRoom }) {
  room.value = fetchedRoom
  fetchMessages({ reset: true })
  if (isMobile.value) showRoomsList.value = false
}

function fetchMoreRooms() {
  emit('fetch-more-rooms')
}

function roomInfo() {
  emit('room-info', room.value)
}

function addRoom() {
  emit('add-room')
}

function searchRoom(val) {
  emit('search-room', { value: val, roomId: room.value.roomId })
}

function fetchMessages(options) {
  emit('fetch-messages', { room: room.value, options })
}

function sendMessage(message) {
  emit('send-message', { ...message, roomId: room.value.roomId })
}

function editMessage(message) {
  emit('edit-message', { ...message, roomId: room.value.roomId })
}

function deleteMessage(message) {
  emit('delete-message', { message, roomId: room.value.roomId })
}

function openFile({ message, file }) {
  if (mediaPreviewEnabledCasted.value && file.action === 'preview') {
    previewFile.value = file.file
    showMediaPreview.value = true
  } else {
    emit('open-file', { message, file })
  }
}

function openUserTag({ user }) {
  emit('open-user-tag', { user })
}

function openFailedMessage({ message }) {
  emit('open-failed-message', {
    message,
    roomId: room.value.roomId
  })
}

function menuActionHandler(ev) {
  emit('menu-action-handler', {
    action: ev,
    roomId: room.value.roomId
  })
}

function roomActionHandler({ action, roomId }) {
  emit('room-action-handler', {
    action,
    roomId
  })
}

function messageActionHandler(ev) {
  emit('message-action-handler', {
    ...ev,
    roomId: room.value.roomId
  })
}

function messageSelectionActionHandler(ev) {
  emit('message-selection-action-handler', {
    ...ev,
    roomId: room.value.roomId
  })
}

function sendMessageReaction(messageReaction) {
  emit('send-message-reaction', {
    ...messageReaction,
    roomId: room.value.roomId
  })
}

function typingMessage(message) {
  emit('typing-message', {
    message,
    roomId: room.value.roomId
  })
}

function textareaActionHandler(message) {
  emit('textarea-action-handler', {
    message,
    roomId: room.value.roomId
  })
}

const setInitialRoom = () => {
  console.log("SETTING INIT ROOM")
  if (roomsCasted.value.length > 0) {
    console.log("Passed Room ID", props.roomId)
    if (props.roomId) {
      const foundRoom = roomsCasted.value.find(r => r.roomId === props.roomId) || roomsCasted.value[0]
      fetchRoom({ room: foundRoom })
    } else {
      fetchRoom({ room: orderedRooms.value[0] })
    }
  }
}

watch(roomsCasted, (newVal, oldVal) => {
  if (newVal.length > 0 && (!room.value || Object.keys(room.value).length === 0)) {
    setInitialRoom()
  }

  if (
    !newVal[0] ||
    (room.value && !newVal.find(r => r.roomId === room.value.roomId))
  ) {
    showRoomsList.value = true
  }

  if (
    !loadingMoreRooms.value &&
    loadFirstRoomCasted.value &&
    newVal[0] &&
    (!oldVal || newVal.length !== oldVal.length)
  ) {
    setInitialRoom()
  }
}, { immediate: true, deep: true })

watch(loadingRoomsCasted, (val) => {
  if (val) {
    room.value = {}
  } else if (roomsCasted.value.length > 0 && (!room.value || Object.keys(room.value).length === 0)) {
    setInitialRoom()
  }
})

watch(() => props.roomId, (newVal, oldVal) => {
  console.log("ROOM ID UPDATED")
  console.log(props.roomId)
  console.log(newVal, oldVal)
  if (newVal && !loadingRoomsCasted.value && roomsCasted.value.length) {
    const foundRoom = roomsCasted.value.find(r => r.roomId === newVal)
    fetchRoom({ room: foundRoom })
  } else if (oldVal && !newVal) {
    room.value = {}
  }
}, { immediate: true })

watch(room, (val) => {
  if (!val || Object.entries(val).length === 0) return

  roomsValidation(val)

  val.users.forEach(user => {
    partcipantsValidation(user)
  })
})

watch(() => props.roomsListOpened, (newVal) => {
  showRoomsList.value = castBoolean(newVal)
}, { immediate: true })

watch(() => props.singleRoom, (newVal) => {
  if (castBoolean(newVal)) {
    showRoomsList.value = false
  }
}, { immediate: true })

onMounted(() => {
  updateResponsive()
  window.addEventListener('resize', (ev) => {
    if (ev.isTrusted) updateResponsive()
  })
  // console.log("Calling mounted set init room")
  // setInitialRoom()
})

onUpdated(() => {
  const slotElements = document.querySelectorAll('[slot]')
  if (slots.value.length !== slotElements.length) {
    slots.value = slotElements
  }
})
</script>

<style lang="scss">
@import '../styles/index.scss';

.vac-card-window {
  &.dark {
    // Add your dark mode styles here
    // background-color: #1a1a1a;
    color: #ffffff;
  }
}

.vac-new-height{
	// height: 75vh !important;
}
</style>