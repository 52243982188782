import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytesResumable
  } from 'firebase/storage'
  
  class FirebaseStorageManager {
	constructor(storage) {
	  this.storage = storage
	  this.FILES_PATH = 'artifact_files'
	//   console.log("Inside FirebaseStorageManager")
	//   console.log(this.storage)
	}
  
	fileRef(currentUserId, messageId, fileName) {
	  return ref(this.storage, `${this.FILES_PATH}/${currentUserId}/${messageId}/${fileName}`)
	}
  
	deleteFile(currentUserId, messageId, file) {
	  return deleteObject(
		this.fileRef(
		  currentUserId,
		  messageId,
		  `${file.name}.${file.extension || file.type}`
		)
	  )
	}
  
	getFileDownloadUrl(ref) {
	  return getDownloadURL(ref)
	}
  
	uploadFileTask(currentUserId, messageId, file, type) {
	  const uploadFileRef = this.fileRef(
		currentUserId,
		messageId,
		`${file.name}.${type}`
	  )
	  return uploadBytesResumable(uploadFileRef, file.blob, {
		contentType: type
	  })
	}
  
	listenUploadImageProgress(
	  currentUserId,
	  messageId,
	  file,
	  type,
	  callback,
	  error,
	  success
	) {
	  const uploadTask = this.uploadFileTask(currentUserId, messageId, file, type)
	  uploadTask.on(
		'state_changed',
		snap => {
		  const progress = Math.round(
			(snap.bytesTransferred / snap.totalBytes) * 100
		  )
		  callback(progress)
		},
		_error => {
		  error(_error)
		},
		async () => {
		  const url = await this.getFileDownloadUrl(uploadTask.snapshot.ref)
		  success(url)
		}
	  )
	}
  }
  
  export default FirebaseStorageManager