export default {
	ROOMS_EMPTY: 'No Artifacts',
	ROOM_EMPTY: 'No History',
	NEW_MESSAGES: 'New Messages',
	MESSAGE_DELETED: 'This message was deleted',
	MESSAGES_EMPTY: 'No messages',
	CONVERSATION_STARTED: 'Project Started on:',
	TYPE_MESSAGE: 'Build Task',
	SEARCH: 'Search',
	IS_ONLINE: 'is online',
	LAST_SEEN: 'last seen ',
	IS_TYPING: 'is writing...',
	CANCEL_SELECT_MESSAGE: 'Cancel'
}
