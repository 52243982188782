import {
	addDoc,
	arrayRemove,
	arrayUnion,
	collection,
	deleteDoc,
	deleteField,
	doc,
	endAt,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	startAfter,
	startAt,
	updateDoc,
	where
  } from 'firebase/firestore'
  
  class FirestoreManager {
	constructor(firestoreDb) {
	  this.firestoreDb = firestoreDb
	//   console.log("Inside FirestoreManager")
	//   console.log(this.firestoreDb)
  
	//   this.USERS_PATH = 'users'
	//   this.ROOMS_PATH = 'chatRooms'
	  this.USERS_PATH = 'artifact_users'
	  this.ROOMS_PATH = 'artifact_projects'
	  this.MESSAGES_PATH = 'messages'
	  this.TIMESTAMP_FIELD = 'timestamp'
	  this.LAST_UPDATED_FIELD = 'lastUpdated'
	  this.TYPING_USERS_FIELD = 'typingUsers'
	  this.MESSAGE_REACTIONS_FIELD = 'reactions'
	  this.ROOM_USERS_FIELD = 'users'
  
	  this.firestoreListener = onSnapshot
	  this.deleteDbField = deleteField()
	}
  
	MESSAGE_PATH(roomId) {
	  return `${this.ROOMS_PATH}/${roomId}/${this.MESSAGES_PATH}`
	}
  
	getDocuments(query) {
	  return getDocs(query).then(docs => {
		return { data: this.formatQueryDataArray(docs), docs: docs.docs }
	  })
	}
  
	getDocument(ref) {
	  return getDoc(ref).then(doc => this.formatQueryDataObject(doc))
	}
  
	addDocument(ref, data) {
	  return addDoc(ref, data)
	}
  
	setDocument(path, docId, data) {
	  return setDoc(doc(this.firestoreDb, path, docId), data)
	}
  
	updateDocument(ref, data) {
	  return updateDoc(ref, data)
	}
  
	deleteDocument(ref, docId) {
	  return deleteDoc(doc(this.firestoreDb, ref, docId))
	}
  
	// Users
	get usersRef() {
	  return collection(this.firestoreDb, this.USERS_PATH)
	}
  
	userRef(userId) {
	  return doc(this.firestoreDb, this.USERS_PATH, userId)
	}
  
	getAllUsers() {
	  return this.getDocuments(query(this.usersRef))
	}
  
	getUser(userId) {
	  return this.getDocument(this.userRef(userId))
	}
  
	addUser(data) {
	  return this.addDocument(this.usersRef, data)
	}
  
	addIdentifiedUser(userId, data) {
	  return this.setDocument(this.USERS_PATH, userId, data)
	}
  
	updateUser(userId, data) {
	  return this.updateDocument(this.userRef(userId), data)
	}
  
	deleteUser(userId) {
	  return this.deleteDocument(this.USERS_PATH, userId)
	}
  
	// Rooms
	get roomsRef() {
	  return collection(this.firestoreDb, this.ROOMS_PATH)
	}
  
	roomRef(roomId) {
	  return doc(this.firestoreDb, this.ROOMS_PATH, roomId)
	}
  
	roomsQuery(currentUserId, roomsPerPage, lastRoom) {
	  if (lastRoom) {
		return query(
		  this.roomsRef,
		  where("users", 'array-contains', currentUserId),
		  orderBy(this.LAST_UPDATED_FIELD, 'desc'),
		  limit(roomsPerPage),
		  startAfter(lastRoom)
		)
	  } else {
		return query(
		  this.roomsRef,
		  where("users", 'array-contains', currentUserId),
		  orderBy(this.LAST_UPDATED_FIELD, 'desc'),
		  limit(roomsPerPage)
		)
	  }
	}
  
	getAllRooms() {
	  return this.getDocuments(query(this.roomsRef))
	}
  
	getRooms(query) {
	  return this.getDocuments(query)
	}
  
	addRoom(data) {
	  return this.addDocument(this.roomsRef, data)
	}
  
	updateRoom(roomId, data) {
	  return this.updateDocument(this.roomRef(roomId), data)
	}
  
	deleteRoom(roomId) {
	  return this.deleteDocument(this.ROOMS_PATH, roomId)
	}
  
	getUserRooms(currentUserId, userId) {
	  return this.getDocuments(
		query(this.roomsRef, where("users", '==', [currentUserId, userId]))
	  )
	}
  
	addRoomUser(roomId, userId) {
	  return this.updateRoom(roomId, {
		[this.ROOM_USERS_FIELD]: arrayUnion(userId)
	  })
	}
  
	removeRoomUser(roomId, userId) {
	  return this.updateRoom(roomId, {
		[this.ROOM_USERS_FIELD]: arrayRemove(userId)
	  })
	}
  
	updateRoomTypingUsers(roomId, currentUserId, action) {
	  const arrayUpdate =
		action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId)
  
	  return this.updateRoom(roomId, { [this.TYPING_USERS_FIELD]: arrayUpdate })
	}
  
	// Messages
	messagesRef(roomId) {
	  return collection(this.firestoreDb, this.MESSAGE_PATH(roomId))
	}
  
	messageRef(roomId, messageId) {
	  return doc(this.firestoreDb, this.MESSAGE_PATH(roomId), messageId)
	}
  
	getMessages(roomId, messagesPerPage, lastLoadedMessage) {
	  if (lastLoadedMessage) {
		return this.getDocuments(
		  query(
			this.messagesRef(roomId),
			orderBy(this.TIMESTAMP_FIELD, 'desc'),
			limit(messagesPerPage),
			startAfter(lastLoadedMessage)
		  )
		)
	  } else if (messagesPerPage) {
		return this.getDocuments(
		  query(
			this.messagesRef(roomId),
			orderBy(this.TIMESTAMP_FIELD, 'desc'),
			limit(messagesPerPage)
		  )
		)
	  } else {
		return this.getDocuments(this.messagesRef(roomId))
	  }
	}
  
	getMessage(roomId, messageId) {
	  return this.getDocument(this.messageRef(roomId, messageId))
	}
  
	addMessage(roomId, data) {
	  return this.addDocument(this.messagesRef(roomId), data)
	}
  
	updateMessage(roomId, messageId, data) {
	//   console.log(roomId, messageId, data)
	  return this.updateDocument(this.messageRef(roomId, messageId), data)
	}
  
	deleteMessage(roomId, messageId) {
	  return this.deleteDocument(this.MESSAGE_PATH(roomId), messageId)
	}
  
	listenRooms(query, callback) {
	  return this.firestoreListener(query, rooms => {
		callback(this.formatQueryDataArray(rooms))
	  })
	}
  
	paginatedMessagesQuery(roomId, lastLoadedMessage, previousLastLoadedMessage) {
	  if (lastLoadedMessage && previousLastLoadedMessage) {
		return query(
		  this.messagesRef(roomId),
		  orderBy(this.TIMESTAMP_FIELD),
		  startAt(lastLoadedMessage),
		  endAt(previousLastLoadedMessage)
		)
	  } else if (lastLoadedMessage) {
		return query(
		  this.messagesRef(roomId),
		  orderBy(this.TIMESTAMP_FIELD),
		  startAt(lastLoadedMessage)
		)
	  } else if (previousLastLoadedMessage) {
		return query(
		  this.messagesRef(roomId),
		  orderBy(this.TIMESTAMP_FIELD),
		  endAt(previousLastLoadedMessage)
		)
	  } else {
		return query(this.messagesRef(roomId), orderBy(this.TIMESTAMP_FIELD))
	  }
	}
  
	listenMessages(roomId, lastLoadedMessage, previousLastLoadedMessage, callback) {
	  return this.firestoreListener(
		this.paginatedMessagesQuery(roomId, lastLoadedMessage, previousLastLoadedMessage),
		messages => {
		  callback(this.formatQueryDataArray(messages))
		}
	  )
	}
  
	formatQueryDataObject(queryData) {
	  return { ...queryData.data(), id: queryData.id }
	}
  
	formatQueryDataArray(queryDataArray) {
	  const formattedData = []
  
	  queryDataArray.forEach(data => {
		formattedData.push(this.formatQueryDataObject(data))
	  })
	  return formattedData
	}
  
	lastMessageQuery(roomId) {
	  return query(this.messagesRef(roomId), orderBy(this.TIMESTAMP_FIELD, 'desc'), limit(1))
	}
  
	listenLastMessage(roomId, callback) {
	  return this.firestoreListener(query(this.lastMessageQuery(roomId)), messages => {
		callback(this.formatQueryDataArray(messages))
	  })
	}
  
	updateMessageReactions(roomId, messageId, currentUserId, reactionUnicode, action) {
	  const arrayUpdate =
		action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId)
  
	  return this.updateMessage(roomId, messageId, {
		[`${this.MESSAGE_REACTIONS_FIELD}.${reactionUnicode}`]: arrayUpdate
	  })
	}
  }
  
  export default FirestoreManager