import { default as b2yVKa1XynJhMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/b2.vue?macro=true";
import { default as build_prey7v9KJBZVwMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/build_pre.vue?macro=true";
import { default as buildrxPteC75wKMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/build.vue?macro=true";
import { default as index1j2xF8xkAPMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/index.vue?macro=true";
import { default as montestioZaFNUHkFMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/montest.vue?macro=true";
import { default as progaHO5lrbUE6Meta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/prog.vue?macro=true";
import { default as _91_46_46_46slug_93vLeqllWXs1Meta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/project/[...slug].vue?macro=true";
import { default as projectsnfe9Kr1tWrMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/projects.vue?macro=true";
import { default as holdD1XSIyjsJGMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/share/hold.js?macro=true";
import { default as _91_46_46_46slug_93xZ6M6JAq9DMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/skyfire/[...slug].vue?macro=true";
import { default as utilsOhXhZL62R4Meta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/skyfire/utils.ts?macro=true";
import { default as testMX8v3XqOMPMeta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/test.vue?macro=true";
import { default as vsc2QYG7KdCh8Meta } from "/Users/jdo/org/brx/artifacts_frontend_brx/pages/vsc.vue?macro=true";
export default [
  {
    name: "b2",
    path: "/b2",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/b2.vue")
  },
  {
    name: "build_pre",
    path: "/build_pre",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/build_pre.vue")
  },
  {
    name: "build",
    path: "/build",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/build.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/index.vue")
  },
  {
    name: "montest",
    path: "/montest",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/montest.vue")
  },
  {
    name: "prog",
    path: "/prog",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/prog.vue")
  },
  {
    name: "project-slug",
    path: "/project/:slug(.*)*",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/project/[...slug].vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/projects.vue")
  },
  {
    name: "share-hold",
    path: "/share/hold",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/share/hold.js")
  },
  {
    name: "skyfire-slug",
    path: "/skyfire/:slug(.*)*",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/skyfire/[...slug].vue")
  },
  {
    name: "skyfire-utils",
    path: "/skyfire/utils",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/skyfire/utils.ts")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/test.vue")
  },
  {
    name: "vsc",
    path: "/vsc",
    component: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/pages/vsc.vue")
  }
]