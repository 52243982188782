import validate from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__355vu4gfbmfgsqwismdka3243i/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-auth": () => import("/Users/jdo/org/brx/artifacts_frontend_brx/middleware/adminAuth.ts"),
  authenticated: () => import("/Users/jdo/org/brx/artifacts_frontend_brx/middleware/authenticated.ts"),
  "chess-event": () => import("/Users/jdo/org/brx/artifacts_frontend_brx/middleware/chessEvent.ts"),
  "exp-auth": () => import("/Users/jdo/org/brx/artifacts_frontend_brx/middleware/expAuth.ts")
}